import { defineStore } from "pinia";

export const useOrderStore = defineStore("OrderStore", {
    state: () => {
        return {
            factoryCosts: {
                bmat: 0,
                rmat: 0,
                emat: 0,
                hemat: 0,
            },
            mpfCosts: {
                bmat: 0,
                rmat: 0,
                emat: 0,
                hemat: 0,
            },
            factory: {
                small_arms: [],
                heavy_arms: [],
                heavy_ammunition: [],
                utilities: [],
                medical: [],
                supplies: [],
                uniforms: [],
            },
            mpf: {
                small_arms: [],
                heavy_arms: [],
                heavy_ammunition: [],
                supplies: [],
                uniforms: [],
                vehicles: [],
                shipables: []
            }
        };
    },
    actions: {
        addFactoryOrders() {
            this.factoryCosts.bmat = 0;
            this.factoryCosts.rmat = 0;
            this.factoryCosts.emat = 0;
            this.factoryCosts.hemat = 0;

            for (const category in this.factory) {
                this.factory[category].forEach((item) => {
                    this.factoryCosts.bmat += (item.cost.bmat) ? item.cost.bmat : 0;
                    this.factoryCosts.rmat += (item.cost.rmat) ? item.cost.rmat : 0;
                    this.factoryCosts.emat += (item.cost.emat) ? item.cost.emat : 0;
                    this.factoryCosts.hemat += (item.cost.hemat) ? item.cost.hemat : 0;
                });
            }
        },
        addMpfOrders() {

            let isEmpty = [];

            for (const category in this.mpf) {
                
                if(!this.mpf[category].length > 0) {
                    isEmpty.push(false);
                } else {
                    isEmpty.push(true);
                }
            

                if(this.mpf[category][0] != undefined) {
                    if (this.mpf[category][0].cost.bmat != undefined) this.mpfCosts.bmat = this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.bmat);
                    if (this.mpf[category][0].cost.rmat != undefined) this.mpfCosts.rmat = this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.rmat);
                    if (this.mpf[category][0].cost.emat != undefined) this.mpfCosts.emat = this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.emat);
                    if (this.mpf[category][0].cost.hemat != undefined) this.mpfCosts.hemat = this.getItemDiscountCosts(this.mpf[category].length, this.mpf[category][0].cost.hemat);
                }
            }

            if(!isEmpty.includes(true)) {
                this.mpfCosts.bmat = 0;
                this.mpfCosts.rmat = 0;
                this.mpfCosts.emat = 0;
                this.mpfCosts.hemat = 0;
            }
        },
        getItemDiscountCosts(number_of_crates, resource_base_cost) {
            let resource_total_cost = 0

            for(let i = 1; i <= number_of_crates; i++) {
                if(i > 5) resource_total_cost += Math.floor(resource_base_cost - (resource_base_cost * (5/10)))
                if(i <= 5) resource_total_cost += Math.floor(resource_base_cost - (resource_base_cost * (i/10)))
            }

            return resource_total_cost
        },
        clearAll() {
            for (const category in this.factory) {
                this.factory[category] = [];

                this.factoryCosts.bmat = 0;
                this.factoryCosts.rmat = 0;
                this.factoryCosts.emat = 0;
                this.factoryCosts.hemat = 0;
            }
            for (const category in this.mpf) {
                this.mpf[category] = [];

                this.mpfCosts.bmat = 0;
                this.mpfCosts.rmat = 0;
                this.mpfCosts.emat = 0;
                this.mpfCosts.hemat = 0;
            }
        }
    }
})