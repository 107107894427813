<script setup>
    
    import { useFactionStore } from '@/stores/FactionStore';

    const factionStore = useFactionStore();

    const updateFaction = (faction) => {
        factionStore.$patch(state => {
            state.factionSetting[0] = faction.target.value
        });
    }
</script>

<template>
    <form class="radio">
        <input type="radio" id="neutral" name="faction" value="neutral" @change="updateFaction" checked />
        <label for="neutral">Neutral</label>
        <input type="radio" id="colonial" name="faction" value="colonial" @change="updateFaction" />
        <label for="colonial">Colonial</label>  
        <input type="radio" id="warden" name="faction" value="warden" @change="updateFaction" />
        <label for="warden">Warden</label>
    </form>
</template>

<style>
    .radio {
      padding: 20px 10px 10px 10px;
    }

    .radio label {
      margin-right: 12px;
    }
</style>