<script>

    import { useFactoryStore } from '@/stores/FactoryStore';
    import { useOrderStore } from '@/stores/OrderStore';
    import { computed } from 'vue';

    export default {
        name: 'OrdersView',
        setup() {
            const factoryStore = useFactoryStore();
            const orderStore = useOrderStore();

            const factory = factoryStore;
            const order = orderStore;
         
            const allCategories = computed(() => {
                let cats = {
                    factory: {
                        small_arms: [],
                        heavy_arms: [],
                        heavy_ammunition: [],
                        utilities: [],
                        medical: [],
                        supplies: [],
                        uniforms: [],
                    },
                    mpf: {
                        small_arms: [],
                        heavy_arms: [],
                        heavy_ammunition: [],
                        supplies: [],
                        uniforms: [],
                        vehicles: [],
                        shipables: [],
                    },
                };

                if(factory.factorySetting == 'factory') {
                    order.factory.small_arms.forEach((item, index) => {
                        if(item.itemCategory == 'small_arms') cats.factory[item.itemCategory].push(item.itemCategory)
                    })

                    order.factory.heavy_arms.forEach((item, index) => {
                        if(item.itemCategory == 'heavy_arms') cats.factory[item.itemCategory].push(item.itemCategory)
                    })

                    order.factory.heavy_ammunition.forEach((item, index) => {
                        if(item.itemCategory == 'heavy_ammunition') cats.factory[item.itemCategory].push(item.itemCategory)
                    })

                    order.factory.utilities.forEach((item, index) => {
                        if(item.itemCategory == 'utilities') cats.factory[item.itemCategory].push(item.itemCategory)
                    })
                    
                    order.factory.supplies.forEach((item, index) => {
                        if(item.itemCategory == 'supplies') cats.factory[item.itemCategory].push(item.itemCategory)
                    })

                    order.factory.medical.forEach((item, index) => {
                        if(item.itemCategory == 'medical') cats.factory[item.itemCategory].push(item.itemCategory)
                    })

                     order.factory.uniforms.forEach((item, index) => {
                        if(item.itemCategory == 'uniforms') cats.factory[item.itemCategory].push(item.itemCategory)
                    })
                }

                if(factory.factorySetting == 'mpf') {
                    order.mpf.small_arms.forEach((item, index) => {
                        if(item.itemCategory == 'small_arms') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                    order.mpf.heavy_arms.forEach((item, index) => {
                        if(item.itemCategory == 'heavy_arms') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                    order.mpf.heavy_ammunition.forEach((item, index) => {
                        if(item.itemCategory == 'heavy_ammunition') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                    order.mpf.supplies.forEach((item, index) => {
                        if(item.itemCategory == 'supplies') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                    order.mpf.uniforms.forEach((item, index) => {
                        if(item.itemCategory == 'uniforms') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                    order.mpf.vehicles.forEach((item, index) => {
                        if(item.itemCategory == 'vehicles') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })

                     order.mpf.shipables.forEach((item, index) => {
                        if(item.itemCategory == 'shipables') cats.mpf[item.itemCategory].push(item.itemCategory)
                    })
                }

                return cats;
            });

            return {
                factory,
                allCategories,
                order,
            }
        },
        methods: {
            removeItem(item) {
                if(this.factory.factorySetting == 'factory') {
                    for (const category in this.order.factory) {
                        for(let i = 0; i < this.order.factory[category].length; i++ ) {
                            if(this.order.factory[category][i] == item) {
                                // console.log(this.order.factory[category][i])
                                this.order.factory[category].splice(i, 1);
                            }
                        }
                    }
                    this.order.addFactoryOrders();
                }

                if(this.factory.factorySetting == 'mpf') {
                    for (const category in this.order.mpf) {
                        for(let i = 0; i < this.order.mpf[category].length; i++ ) {
                            if(this.order.mpf[category][i] == item) {
                                // console.log(this.order.mpf[category][i])
                                this.order.mpf[category].splice(i, 1);
                            }
                        }
                    }
                    this.order.addMpfOrders();
                }
            }
        }
    }

</script>

<template>
    <div  v-if="factory.factorySetting == 'factory'">
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/small_arms.png'" alt="Small Arms Category" class="categoryImage"/>     
                <p class="message" v-if="!allCategories.factory['small_arms'].includes('small_arms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.small_arms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'small_arms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)">
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['small_arms'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/heavy_arms.png'" alt="Heavy Arms Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['heavy_arms'].includes('heavy_arms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.heavy_arms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'heavy_arms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['heavy_arms'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/heavy_ammunition.png'" alt="Heavy Ammunition Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['heavy_ammunition'].includes('heavy_ammunition')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.heavy_ammunition" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'heavy_ammunition'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['heavy_ammunition'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/utilities.png'" alt="Utilities Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['utilities'].includes('utilities')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory['utilities']" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'utilities'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['utilities'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/supplies.png'" alt="Supplies Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['supplies'].includes('supplies')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.supplies" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'supplies'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                     
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['supplies'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
         <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/medical.png'" alt="Medical Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['medical'].includes('medical')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.medical" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'medical'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                     
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['medical'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/uniforms.png'" alt="Uniforms Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.factory['uniforms'].includes('uniforms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.factory.uniforms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'uniforms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                      
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.factory['uniforms'].length > 4">
                    Warn: factory in-game only supports up to 4 orders.
                </div>
            </transition>
        </div>
    </div>
    <div  v-if="factory.factorySetting == 'mpf'">
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/small_arms.png'" alt="Small Arms Category" class="categoryImage"/>     
                <p class="message" v-if="!allCategories.mpf['small_arms'].includes('small_arms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.small_arms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'small_arms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)">
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['small_arms'].length > 9">
                    Warn: MPF in-game only supports up to 9 orders for Small Arms.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/heavy_arms.png'" alt="Heavy Arms Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['heavy_arms'].includes('heavy_arms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.heavy_arms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'heavy_arms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['heavy_arms'].length > 9">
                    Warn: MPF in-game only supports up to 9 orders for Heavy Arms.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/heavy_ammunition.png'" alt="Heavy Ammunition Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['heavy_ammunition'].includes('heavy_ammunition')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.heavy_ammunition" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'heavy_ammunition'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['heavy_ammunition'].length > 9">
                    Warn: MPF in-game only supports up to 9 orders for Heavy Ammunition.
                </div>
            </transition>
        </div>
         <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/supplies.png'" alt="Supplies Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['supplies'].includes('supplies')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.supplies" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'supplies'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                      
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['supplies'].length > 9">
                    Warn: MPF in-game only supports up to 9 orders for Supplies.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/uniforms.png'" alt="Uniforms Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['uniforms'].includes('uniforms')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.uniforms" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'uniforms'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                      
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['uniforms'].length > 9">
                    Warn: MPF in-game only supports up to 9 orders for Uniforms.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/vehicles.png'" alt="Vehicles Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['vehicles'].includes('vehicles')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.vehicles" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'vehicles'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                      
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['vehicles'].length > 5">
                    Warn: MPF in-game only supports up to 5 orders for Vehicles.
                </div>
            </transition>
        </div>
        <div class="categoryContainer">
            <div class="categoryContainerFlex">
                <img :src="'./assets/images/tabUI/shipables.png'" alt="Shipables Category" class="categoryImage"/>
                <p class="message" v-if="!allCategories.mpf['shipables'].includes('shipables')">No items added.</p>
                <div class="buttonContainer">
                    <div v-for="(order, key) in order.mpf.shipables" :key="key" class="buttonWrapper">
                        <button class="categorySubject" v-if="order.itemCategory == 'shipables'" :style="{ backgroundImage: 'url(./assets/images/items/' + order.imgName + ')' }" @click="removeItem(order, key)" >
                            {{order.itemName}}
                        </button>
                    </div>
                </div>
            </div>
            <transition name="fade" appear>
                <div class="warning" v-if="allCategories.mpf['shipables'].length > 5">
                    Warn: MPF in-game only supports up to 5 orders for Shipables.
                </div>
            </transition>
        </div>
    </div>
</template>

<style scoped>
    .hidden {
        display: none;
    }

    .categoryContainerFlex {
        display: flex;
        align-items: center;
        height: 40px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .warning {
        background-color: #FFCC00;
        margin-top: 10px;
        padding: 5px;
    }

    .categoryContainer {
        color: #000;
        background-color: #4D4D4D;
        margin: 10px;
        padding: 10px;

        display: flex;
        flex-direction: column;
        
    }

    .buttonContainer {
        height: 40px;
        margin: 10px 10px 10px 0px;
        padding: 10px;

        /* display: flex;
        flex-wrap: wrap;

        align-items: center; */

        width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;

        display: flex;
    }

    .buttonContainer button {
        border-top: 2px solid #767676;
        border-left: 2px solid #767676;
        border-bottom: 2px solid #212121;
        border-right: 2px solid #212121;

        
    }

    .buttonContainer button:last-child {
        /* margin-right: 0px; */
    }

    .item {
        width: 30px;
        height: 30px;
        background-color: orange;
        margin: 10px;
    }

    .categorySubject {
        width: 40px;
        height: 40px;
        background-color: #3A3A3A;
        margin-right: 10px;

        cursor: pointer;
    }

    .categorySubject:hover {
        background-color: #eb4034;
    }

    button {
        text-indent: -9999em;
        background-size: cover;
    }

    .message {
        margin: 0px 10px;
        color: #fff;
        width: 100%;
    }

    .categoryImage {
        width: 40px;
        height: 40px;
        /* background-color: #3A3A3A; */
    }

    .categoryTotalCost {
        background-color: #3A3A3A;
        color: white;
        height: 40px;
        padding: 0px 10px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>