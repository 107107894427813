<template>
    <footer>
        <p><a href="https://www.foxholegame.com/" target="_blank" rel="noopener">Foxhole</a> is a registered trademark of <a href="https://www.clapfootgames.com/" target="_blank" rel="noopener">Clapfoot Inc</a>.</p>
        <nav>
            <ul>
                <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSfJCz_-NDfmoqoE8FdX-BwwOT_fv1F49AJl26wilFhL3X_RHw/viewform?usp=pp_url&entry.983802510=General+Feedback" target="_blank" rel="noopener">Give Feedback</a></li>
                <li><a href="https://logiunion.com/" target="_blank" rel="noopener">logiunion.com</a></li>
                <li><a href="/changelog.html">Changelog</a></li>
                <li><a href="/privacy-policy.html">Privacy Policy</a></li>
                <li><a href="https://foxholelogi.com/assets/foxhole.json" target="_blank">FoxholeLogi JSON</a></li>
            </ul>
        </nav>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
    footer {
        max-width: 684px;
        margin: 0 auto;

        text-align: center;
        height: 120px;
    }

    a {
        color: #BA9B6D;
    }

    a:hover {
        color: #E77C48;
    }

    nav ul {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2rem;

        padding-left: 0px;
        margin: 0px;
    }

    nav ul li {
        display: inline-block;
        list-style-type: none;
        margin: 0px 15px;
    }
</style>