<script setup>
    import ClearButton from '../components/ClearButton.vue';
    import { useFactoryStore } from '@/stores/FactoryStore';

    const factoryStore = useFactoryStore();

    const updateFactory = (factory) => {
        let temp = []; 
        temp.push(factory.target.value); 

        factoryStore.$patch(state => {
            state.factorySetting = temp;
        });
    }

</script>




<template>
    <div class="flex__container">
        <form>
            <div class="title">
                Type of factory used:
            </div>
            <input type="radio" id="factory" name="factoryType" value="factory" @change="updateFactory" checked>
            <label for="factory">Factory</label>

            <input type="radio" id="mpf" name="factoryType" value="mpf" @change="updateFactory">
            <label for="mpf">Mass Production Factory</label>
        </form>
        <ClearButton />
    </div>
</template>

<style scoped>
    .flex__container {
        display: flex;
        justify-content: space-between;
    }

    button {
        margin-top: auto;
        margin-bottom: 0;
    }
    label {
        margin-right: 12px;
    }

    form {
        margin-left: 5px;
    }

    input {
        margin-right: 5px;
    }

    .title {
        margin-left: 5px;
        padding: 10px 0;
    }
</style>

