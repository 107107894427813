<template>
    <div class="heading_title">Total Costs</div>
    <div class="resources">
        <div class="container">
            <span class="title">Bmats</span>
            <div class="resource">
                <img src="/assets/images/resources/bmat.png" alt="Basic materials"/>
                <span v-if="factoryStore.factorySetting == 'factory'">
                    {{orderStore.factoryCosts.bmat}}
                </span>
                <span v-if="factoryStore.factorySetting == 'mpf'">
                    {{orderStore.mpfCosts.bmat}}
                </span>
            </div>
        </div>
        <div class="container">
            <span class="title">Rmats</span>
            <div class="resource">
                <img src='/assets/images/resources/rmat.png' alt="Refined materials"/>
                <span v-if="factoryStore.factorySetting == 'factory'">
                    {{orderStore.factoryCosts.rmat}}
                </span>
                <span v-if="factoryStore.factorySetting == 'mpf'">
                    {{orderStore.mpfCosts.rmat}}
                </span>
            </div>
        </div>
        <div class="container">
            <span class="title">Emats</span>
            <div class="resource">
                <img src='/assets/images/resources/emat.png' alt="Explosive materials"/>
                <span v-if="factoryStore.factorySetting == 'factory'">
                    {{orderStore.factoryCosts.emat}}
                </span>
                <span v-if="factoryStore.factorySetting == 'mpf'">
                    {{orderStore.mpfCosts.emat}}
                </span>
            </div>
        </div>
        <div class="container">
            <span class="title">Hemats</span>
            <div class="resource">
                <img src='/assets/images/resources/hemat.png' alt="Heavy explosive materials"/>
                <span v-if="factoryStore.factorySetting == 'factory'">
                    {{orderStore.factoryCosts.hemat}}
                </span>
                <span v-if="factoryStore.factorySetting == 'mpf'">
                    {{orderStore.mpfCosts.hemat}}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>

    import { useOrderStore } from '@/stores/OrderStore';
    import { useFactoryStore } from '@/stores/FactoryStore';

    const orderStore = useOrderStore();
    const factoryStore = useFactoryStore();

</script>

<style scoped>
    .heading_title {
        display: block;
        width: 100%;
        border-bottom: 1px solid gray;
        padding: 10px 0;
        margin: 0 10px;
        font-size: 1.1rem;
    }

    section {
        background-color: #3A3A3A;
        max-width: 560px;

        height: 100%;

        margin: 0px 20px;
    }

    .resources {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 

        margin-bottom: 1rem;
    }

    .container {
        display: flex;
        flex-direction: column;

        padding: 0 10px 10px 10px;
        width: 120px;
    }

    .title {
        padding-left: 0px;
    }

    .resource {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 120px;
        background-color: #4D4D4D;
    }

    img {
        margin-left: 5px;
        width: 30px;
    }

    span {
        padding: 10px;
    }
</style>