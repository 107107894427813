<template>
  <section>
    <div class="resources">
        <Resource />
        <Crate />
    </div>
    <hr />
    <div class="displaySection">
        <Factory />
        <OrdersView />
    </div>
  </section>
</template>

<script>
import Resource from '../Resource.vue'
import Crate from '../Crate.vue'
import Factory from '../Factory.vue'
import OrdersView from '../OrdersView.vue'

export default {
    name: 'ResourceLayout',
    components: {
        Resource,
        Crate,
        Factory,
        OrdersView
    },
}
</script>

<style scoped>
    section {
        background-color: #3A3A3A;
        max-width: 560px;

        height: 100%;

        margin: 0px 20px;
    }
    hr {
        height: 1px;
        background-color: #3A3A3A;
        border: none;
    }
    .tabs__container {
        display: flex;
        justify-content: space-between;
    }
    .resources {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; 
    }

    @media screen and (max-width: 1340px) {
        section {
            width: 100%;

            margin-bottom: 40px;
        }
    }

    @media screen and (max-width: 1340px) {
        section {
            max-width: 684px;
        }
    }

    /* .displaySection {
        flex-direction: row;
    } */
</style>