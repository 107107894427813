<template>
  <button @click="clearAll()" style="background-image: url('./assets/images/tabUI/clear.png')">Remove All Items</button>
</template>

<script>
    import { useOrderStore } from '@/stores/OrderStore';

    export default {
        name: 'ClearButon',
        setup() {
            const orderStore = useOrderStore();
            const order = orderStore;

            return {
                order
            }
        },
        methods: {
            clearAll() {
                this.order.clearAll();
            }
        }
    }
</script>

<style scoped>
    button {
        width: 45px;
        height: 45px;
        margin: 10px;
        cursor: pointer;
        text-indent: -9999em;
        background-size: cover;
        background-color: #3A3A3A;
        border-top: 2px solid #767676;
        border-left: 2px solid #767676;
        border-bottom: 2px solid #212121;
        border-right: 2px solid #212121;
    }
    button:active {
         background-color: #ff2424;
    }
</style>