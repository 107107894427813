<template>
    <section>
        <CategoryTitle title="items" />
        <CategoryItems />
    </section>
</template>

<script>
import CategoryTitle from '../UIComponents/containers/CategoryTitle'
import CategoryItems from '../UIComponents/containers/CategoryItems'

export default {
    name: 'ItemLayout',
    components: {
        CategoryTitle,
        CategoryItems
    }
}
</script>

<style scoped>
    section {
        width: 684px;
        margin: 0px 20px;
    }

    @media screen and (max-width: 1340px) {
        section {
            width: 100%;
        }
    }
</style>