<script>
import { useCategoryStore } from '@/stores/CategoryStore';
import { useFactoryStore } from '@/stores/FactoryStore';

export default {
  name: 'Category',
  setup() {
    const categoryStore = useCategoryStore();
    const factoryStore = useFactoryStore();

    const category = categoryStore;
    const factory = factoryStore;

    const updateCategory = (category) => {
      categoryStore.categories.forEach(button => {
        (button.value == category) ? button.isActive = true : button.isActive = false;
      });

      categoryStore.$patch(state => {
        state.categorySetting = category;
      });
    }

    return {
      category,
      factory,
      updateCategory
    }
  },
}

</script>

<template>

      <div
        v-for="category in this.category.categories" 
        :key="category.value"
      >  

        <button 
          :class="category.isActive ? 'active' : ''"
          :style="{backgroundImage:'url('+category.iconPath+')'}"

          v-if="category.displayed.includes(this.factory.factorySetting[0])"
          v-on:click="updateCategory(category.value)"
        >
          {{category.displayName}}
        </button>
      </div>

</template>

<style scoped>
  div {
    display: inline-block;
  }
  button {
        width: 45px;
        height: 45px;
        margin: 10px;

        cursor: pointer;
        text-indent: -9999em;

        background-size: cover;
        background-color: #3A3A3A;

        border-top: 2px solid #767676;
        border-left: 2px solid #767676;
        border-bottom: 2px solid #212121;
        border-right: 2px solid #212121;
    }

  .active {
      background-color: #E77C48;
  }
</style>