import { defineStore } from "pinia";
import { useCategoryStore } from '@/stores/CategoryStore';
import { useFactionStore } from '@/stores/FactionStore';
import { useFactoryStore } from '@/stores/FactoryStore';

export const useItemStore = defineStore("ItemStore", {


    state: () => {
        return {
            items: [],
            itemsFiltered: [],
        };
    },
    actions: {
        async fill() {
            this.items = (await import("../../public/assets/foxhole.json")).default
            this.copyItems();
            this.filterList();
        },
        copyItems() {
            this.itemsFiltered = this.items;
        },
        filterList() {
            const categoryStore = useCategoryStore();
            const factionStore = useFactionStore();
            const factoryStore = useFactoryStore();

            let selectedCategory = categoryStore.categorySetting;
            let selectedFaction = factionStore.factionSetting;
            let selectedFactory = factoryStore.factorySetting;

            // console.log('----------');
            // console.log(selectedCategory);
            // console.log(selectedFaction);
            // console.log(selectedFactory);
            // console.log('----------');

            let totalList = this.items;

            // console.log(totalList)

            function filterCategory(item) {     
                if (item.itemCategory == selectedCategory) return item;
            }

            function filterFaction(item) {
                if (item.faction.includes(selectedFaction[0])) return item;
            }

            function filterFactory(item) {
                if (item.craftLocation.includes(selectedFactory[0])) return item;
            }

            let filteredList = totalList
                .filter(filterCategory)
                .filter(filterFaction)
                .filter(filterFactory)

            // console.log(filteredList) 

            this.itemsFiltered = filteredList;
        }
    }
});