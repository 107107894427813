<template>
    <div>
        <h1>{{ title }}</h1>
        <!-- <button>?</button> -->
    </div>
</template>

<script>
export default {
    name: "CategoryTitle",
    props: {
        title: String
    }
}
</script>

<style scoped>
    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 24px;
        padding: 8px 12px;
        max-width: 660px;

        margin-left: auto;
        margin-right: auto;
        background-color: #737472;
    }

    h1 {
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 900;
        font-size: 16px;

        border-radius: 30px;
        border: 2px solid #fff;
        color: #73726F;
        background-color: #BEC2C0;

        width: 10px;
        height: 10px;
        padding: 10px;

    }
</style>