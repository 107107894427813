<template>
  <Home />
</template>

<script>
import Home from './views/Home'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
  html {
      overflow-y: scroll;
  }

  body {
      color: #fff;
      background-color: #04293d;
      font-family: Renner;
  }

  html, body {
      height: 130%;
  }

  .page-center {
    display: flex;
    justify-content: center;
  }

  .container {
    width: 648px;
  }
  @font-face {
    font-family: Renner;
    src: url('../public/assets/font/Renner_\ 400\ Book.ttf');
    font-weight: 400;
  }
</style>
