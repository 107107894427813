<template>
  <header>
      <h1>{{ pageTitle }}</h1>
      <div class="message">
            <p>Site is current with <a href="https://www.foxholegame.com/post/update-57-release-notes" target="_blank" rel="noopener noreferrer">Update 57</a>!</p>
            <p>Comments or a found a bug? <a href="https://docs.google.com/forms/d/e/1FAIpQLSfJCz_-NDfmoqoE8FdX-BwwOT_fv1F49AJl26wilFhL3X_RHw/viewform?usp=pp_url&entry.983802510=General+Feedback" target="_blank" rel="noopener">Leave me a message</a>.</p>
      </div>
  </header>
</template>

<script>
export default {
    name: 'Header',
    props: {
        pageTitle: String
    }
}
</script>

<style scoped>
    .message {
        /* max-width: 800px; */
        max-width: 668px;
        margin: 0 auto;
        padding: .5rem;
        background-color: #000;
        text-align: center;
        margin-bottom: 2rem;
    }
    .message__left {
        text-align: left;
    }
    .message__left li {
        text-align: left;
    }
    .message a {
        color: #E77C48;
    }
    h1 {
        text-align: center;
    }
</style>