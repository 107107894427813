<template>
  <Header pageTitle="Foxhole Logistics Calculator" />
    <div class="main-panels">
      <ResourceLayout :itemsToAdd="activeItems" @update-factory="updateFactory"/>
      <ItemLayout @submit-item="submitItem" :factorySetting="factorySetting"/>
    </div>
  <Footer />
</template>

<script>
import Header from '../components/layouts/Header'
import ItemLayout from '../components/layouts/ItemLayout'
import ResourceLayout from '../components/layouts/ResourceLayout'
import Footer from '../components/layouts/Footer'

export default {
  name: 'Home',
  components: {
    Header,
    ResourceLayout,
    ItemLayout,
    Footer,
  },
  data() {
    return {
      activeItems: {
        small_arms: [],
        heavy_arms: [],
        heavy_ammunition: [],
        utilities: [],
        medical: [],
        supplies: [],
        uniforms: [],
        vehicles: [],
        shipables: [],
      },
      factorySetting: 'factory'
    }
  },
  methods: {
    submitItem(item) {
      if(item.itemCategory == "small_arms") this.activeItems.small_arms.push(item)
      if(item.itemCategory == "heavy_arms") this.activeItems.heavy_arms.push(item)
      if(item.itemCategory == "heavy_ammunition") this.activeItems.heavy_ammunition.push(item)
      if(item.itemCategory == "utilities") this.activeItems.utilities.push(item)
      if(item.itemCategory == "medical") this.activeItems.medical.push(item)
      if(item.itemCategory == "supplies") this.activeItems.supplies.push(item)
      if(item.itemCategory == "uniforms") this.activeItems.uniforms.push(item)
      if(item.itemCategory == "vehicles") this.activeItems.vehicles.push(item)
      if(item.itemCategory == "shipables") this.activeItems.shipables.push(item)
    },
    updateFactory(factory) {
      

      this.activeItems.small_arms = []
      this.activeItems.heavy_arms = []
      this.activeItems.heavy_ammunition = []
      this.activeItems.utilities = []
      this.activeItems.medical = []
      this.activeItems.supplies = []
      this.activeItems.uniforms = []
      this.activeItems.vehicles = []
      this.activeItems.shipables = []

      this.factorySetting = factory
    }
  }
}
</script>

<style scoped>

  .main-panels {
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    justify-content: center;
  }

  @media screen and (max-width: 1340px) {
    
    .main-panels {
      flex-direction: column;
      align-items: center;
    }
  }
</style>