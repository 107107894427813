<template>
  <div class="tabs">
    <Faction />
  </div>
  <div class="tabs">
    <Category />
  </div>
  <section>
    <ItemList />
  </section>
</template>

<script>
import Faction from '../../Faction.vue';
import Category from '../../Category.vue';
import ItemList from '../../ItemList.vue';


export default {
    name: 'CategoryItems',
    components: {
        ItemList,
        Category,
        Faction,
    },
}
</script>

<style scoped>
    section {
      display: flex;
      flex-wrap: wrap;

      max-width: 672px;
      margin: 0 auto;

      padding: 6px;
      background-color: #000;
    }

    .tabs {
        max-width: 684px;
        margin: 0 auto;
        
        background-color: #3A3A3A;
    }

    
</style>