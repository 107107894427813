<script setup>

    import Item from '../components/Item.vue';

    import { useItemStore } from '@/stores/ItemStore';
    import { useCategoryStore } from '@/stores/CategoryStore';
    import { useFactionStore } from '@/stores/FactionStore';
    import { useFactoryStore } from '@/stores/FactoryStore';

    const itemStore = useItemStore();
    const categoryStore = useCategoryStore();
    const factionStore = useFactionStore();
    const factoryStore = useFactoryStore();

    itemStore.fill();

    categoryStore.$subscribe((mutation, state) => {
      itemStore.filterList();
    });

    factionStore.$subscribe((mutation, state) => {
      itemStore.filterList();
    });

    factoryStore.$subscribe((mutation, state) => {
      itemStore.filterList();
    });

</script>

<template>
  <Item v-for="item in itemStore.itemsFiltered" :key="item.itemName" :item="item"/>
</template>

<style scoped>
  section {
    display: flex;
    flex-wrap: wrap;

    max-width: 672px;
    margin: 0 auto;

    padding: 6px;
    background-color: #000;
  }
</style>