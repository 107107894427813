import { defineStore } from "pinia";

export const useCategoryStore = defineStore("CategoryStore", {
    state: () => {
        return {
            categorySetting: 'small_arms',
            categories: [
                {
                    value: 'small_arms',
                    displayName: 'Small Arms',
                    displayed: ['factory', 'mpf'],
                    isActive: true,
                    iconPath: "./assets/images/tabUI/small_arms.png"
                },
                {
                    value: 'heavy_arms',
                    displayName: 'Heavy Arms',
                    displayed: ['factory', 'mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/heavy_arms.png"
                },
                {
                    value: 'heavy_ammunition',
                    displayName: 'Heavy Ammunition',
                    displayed: ['factory', 'mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/heavy_ammunition.png"
                },
                {
                    value: 'utilities',
                    displayName: 'Utilities',
                    displayed: ['factory'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/utilities.png"
                },
                {
                    value: 'supplies',
                    displayName: 'Supplies',
                    displayed: ['factory', 'mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/supplies.png"
                },
                {
                    value: 'medical',
                    displayName: 'Medical',
                    displayed: ['factory'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/medical.png"
                },
                {
                    value: 'uniforms',
                    displayName: 'Uniforms',
                    displayed: ['factory', 'mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/uniforms.png"
                },
                {
                    value: 'vehicles',
                    displayName: 'Vehicles',
                    displayed: ['mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/vehicles.png"
                },
                {
                    value: 'shipables',
                    displayName: 'Shipables',
                    displayed: ['mpf'],
                    isActive: false,
                    iconPath: "./assets/images/tabUI/shipables.png"
                }
            ]
        };
    } 
});